<template>
  <main>
    <trac-loading v-if="isLoading" />
    <trac-back-button>Back</trac-back-button>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-12 mr-4 md:mr-0 my-4 md:my-0">
      <div>
        <carousel :autoplayTimeout="5000" :autoplay="true" :per-page="1" :mouse-drag="true" :loop="true">
          <slide>
            <div class="rounded-lg py-10 m-4">
              <p class="text-center text-sm to-gray-800 mt-2 font-medium">
                Traction ONE
              </p>
              <div class="flex items-center mt-10 justify-center px-2">
                <div>
                  <img src="@/assets/images/terminals/TractionAndroid.png" class="h-40" alt="" />
                </div>
              </div>
            </div>
          </slide>
          <slide>
            <div class="rounded-lg m-4 py-10">
              <p class="text-center text-sm to-gray-800 mt-2 font-medium">
                Traction ONE
              </p>
              <div class="flex items-center mt-10 justify-center px-2">
                <div>
                  <img src="@/assets/images/terminals/TractionAndroid2.png" class="h-40" alt="" />
                </div>
              </div>
            </div>
          </slide>
          <slide>
            <div class="rounded-lg m-4 py-10">
              <p class="text-center text-sm to-gray-800 mt-2 font-medium">
                Traction ONE
              </p>
              <div class="flex items-center mt-10 justify-center px-2">
                <div>
                  <img src="@/assets/images/terminals/TractionAndroid3.png" class="h-40" alt="" />
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
      <div class="md:px-20">
        <trac-input data-test="nae-of-pos" placeholder="Number of Pos" class="leading-7" type="number" v-model="payload.quantity"></trac-input>

        <div>
          <select name="" class="
              border
              py-3
              leading-7
              mt-4
              text-gray-500
              pl-2
              w-full
              text-xs
              rounded
              input-area
            " id="" v-model="payload.preferredNetworkProvider">
            <option :value="null" disabled>Select Preferred Network</option>
            <option value="9MOBILE">
              9MOBILE
            </option>
            <option value="MTN">
              MTN
            </option>
            <option value="AIRTEL">
              AIRTEL
            </option>
            <option value="GLO">
              GLO
            </option>
          </select>
        </div>

        <div>
          <select name="" data-test="select-option-plan" class="
              border
              py-3
              leading-7
              mt-4
              text-gray-500
              pl-2
              w-full
              text-xs
              rounded
              input-area
            " id="" v-model="choose">
            <option :value="null" disabled>Select Option Plan</option>

            <option value="outright">
              Outright Purchase ({{ outrightPurchase | formatPrice }})
            </option>
            <option value="lease">
              Lease to own ({{ leaseToOwn | formatPrice }})
            </option>
            <option value="subscription">
              Subscription ({{ subscription | formatPrice }})
            </option>
          </select>
        </div>

        <trac-input data-test="terminal-phone-number" placeholder="Phone Number" class="leading-7 mt-4" v-model="payload.deliveryPhoneNumber"
          type="number"></trac-input>
        <trac-input placeholder="Address" data-test="address-field" class="leading-7 mt-4" type="text"
          v-model="payload.deliveryAddress"></trac-input>
        <trac-input data-test="terminal-city-field" placeholder="City" class="leading-7 mt-4" type="text" v-model="payload.deliveryCity"></trac-input>

        <div>
          <div data-test="select-state-dropdown" @click.stop="stateDropdown = !stateDropdown" class="
              border
              py-3
              leading-5
              mt-4
              text-gray-500
              pl-5
              w-full
              text-xs
              rounded
              input-area
            ">
            {{ payload.deliveryState || "Select State" }}
          </div>
          <div v-click-outside="close" v-if="stateDropdown" style="max-height: 19rem" class="
              h-auto
              max-w-sm
              w-full
              z-10
              overflow-y-auto
              absolute
              bg-white
              mt-1
              shadow
              border
              p-2
              rounded-md
            ">
            <input required type="text" v-model="stateSearch" class="
                shadow
                appearance-none
                text-xs
                border
                rounded
                w-full
                py-2
                leading-5
                px-3
                text-gray-700
                mt-1
                focus:outline-none
                focus:shadow-outline
              " />
            <div class="mt-2" v-for="(state, index) in formatStates" :key="index">
              <div class="capitalize" @click="updateState(state)">
                {{ state.name }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div data-test="select-local-government" :class="{
  ' pointer-events-none': local_governments.length == 0,
}" @click.stop="lgDropdown = !lgDropdown" class="
              border
              py-3
              leading-5
              mt-4
              text-gray-500
              pl-5
              w-full
              text-xs
              rounded
              input-area
            ">
            {{ payload.deliverylga || "Select Local Government" }}
          </div>
          <div v-click-outside="closeTwo" v-if="lgDropdown" style="max-height: 19rem" class="
              h-auto
              max-w-sm
              z-10
              w-full
              overflow-y-auto
              absolute
              bg-white
              mt-1
              shadow
              border
              p-2
              rounded-md
            ">
            <input required type="text" v-model="lgSearch" class="
                shadow
                appearance-none
                text-xs
                border
                rounded
                w-full
                py-2
                leading-5
                px-3
                text-gray-700
                mt-1
                focus:outline-none
                focus:shadow-outline
              " />
            <div class="mt-2" v-for="lg in formatLg" :key="lg">
              <div class="capitalize text-sm" @click="updateLg(lg)">
                {{ lg }}
              </div>
            </div>
          </div>
        </div>
        <trac-input data-test="delivery-date" placeholder="Delivery Date" class="leading-7 mt-4" type="date" :min="today"
          v-model="payload.deliveryDate"></trac-input>
        <trac-input data-test="referral-code" placeholder="Referral code" class="leading-7 mt-4" type="text"
          v-model="payload.saleRefCode"></trac-input>

        <trac-button @click.native="sendRequest" class="w-full mt-4">
          <p>Send Request</p>
        </trac-button>
      </div>
    </div>
  </main>
</template>

<script>
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { mapGetters } from "vuex";
import { eventBus } from "./../../main";
import ClickOutside from "vue-click-outside";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: false,
      moment,
      stateSearch: "",
      stateDropdown: false,
      states: [],
      lgDropdown: false,
      lgSearch: "",
      local_governments: [],
      today: "",
      outrightPurchase: "",
      leaseToOwn: "",
      subscription: "",
      choose: null,
      payload: {
        businessId: null,
        quantity: null,
        deliveryPhoneNumber: null,
        deliveryAddress: null,
        deliveryCity: null,
        deliveryState: null,
        deliveryDate: null,
        deliverylga: null,
        saleRefCode: null,
        preferredNetworkProvider: null,
        terminalPrice: {
          terminalType: "ANDROID",
          terminalImage: "",
          outrightPurchase: "",
          leaseToOwn: "",
          subscription: "",
          accessories: [],
        },
      },
    };
  },
  directives: {
    ClickOutside,
  },
  watch: {
    choose(x) {
      if (x === "outright") {
        this.payload.terminalPrice.outrightPurchase = this.outrightPurchase;
        this.payload.terminalPrice.leaseToOwn = "";
        this.payload.terminalPrice.subscription = "";
      } else if (x === "lease") {
        this.payload.terminalPrice.outrightPurchase = "";
        this.payload.terminalPrice.leaseToOwn = this.leaseToOwn;
        this.payload.terminalPrice.subscription = "";
      } else {
        this.payload.terminalPrice.outrightPurchase = "";
        this.payload.terminalPrice.leaseToOwn = this.subscription;
        this.payload.terminalPrice.subscription = "";
      }
    },
  },
  methods: {
    updateState(state) {
      this.payload.deliveryState = state.name;
      this.local_governments = state.local_governments;
      this.stateDropdown = false;
      this.stateSearch = "";
    },
    updateLg(lg) {
      this.payload.deliverylga = lg;
      this.lgDropdown = false;
      this.lgSearch = "";
    },
    close() {
      this.stateDropdown = false;
      this.stateSearch = "";
    },
    closeTwo() {
      this.lgDropdown = false;
      this.lgSearch = "";
    },

    async sendRequest() {
      if (this.payload.quantity === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Quantity",
        });
        return;
      }
      if (this.payload.preferredNetworkProvider === null) {
        eventBus.$emit("trac-alert", {
          message: "Select a Network Provider",
        });
        return;
      }
      if (this.choose === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Options",
        });
        return;
      }
      if (this.payload.deliveryPhoneNumber === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Your Phone Number",
        });
        return;
      }
      var arr = ["080", "081", "090", "091", "070", "071"];
      var test1 = arr.some((el) =>
        this.payload.deliveryPhoneNumber.startsWith(el)
      );
      //  ||
      if (!test1) {
        eventBus.$emit("trac-alert", {
          message: "Phone Number not valid",
        });
        return;
      }
      if (+this.payload.deliveryPhoneNumber.length !== 11) {
        eventBus.$emit("trac-alert", {
          message: "Phone Number not valid",
        });
        return;
      }
      if (this.payload.deliveryAddress === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Address",
        });
        return;
      }
      if (this.payload.deliveryCity === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter City",
        });
        return;
      }
      if (this.payload.deliveryState === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter State",
        });
        return;
      }
      if (this.payload.deliverylga === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Local Government",
        });
        return;
      }
      if (this.payload.deliveryDate === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Delivery Date",
        });
        return;
      }
      this.payload.quantity = +this.payload.quantity;

      this.isLoading = true;

      let response = await this.$store.dispatch(
        "ADD_TERMINAL_REQUEST",
        {...this.payload, source: "WEB"}
      );
      if (response.status) {
        eventBus.$emit("trac-alert", {
          message: response.message || "Terminal requested successfully",
        });
        
        this.payload.quantity = null;
        this.payload.deliveryPhoneNumber = null;
        this.payload.deliveryAddress = null;
        this.payload.deliveryCity = null;
        this.payload.deliveryState = null;
        this.payload.deliverylga = null;
        this.payload.deliveryDate = null;
        this.payload.saleRefCode = null;
        this.payload.preferredNetworkProvider = null;
        this.choose = null;
      }

      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters(["GET_ALL_TERMINAL_PRICINGS"]),
    mposTerminal() {
      return this.GET_ALL_TERMINAL_PRICINGS.data.items.find(
        (x) => x.terminalType === "ANDROID"
      );
    },
    formatStates() {
      return this.states
        .filter((state) => {
          return state.name
            .toLowerCase()
            .includes(this.stateSearch.toLowerCase());
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    formatLg() {
      return this.local_governments
        .filter((state) => {
          return state.toLowerCase().includes(this.lgSearch.toLowerCase());
        })
        .sort((a, b) => a.localeCompare(b));
    },
  },
  async created() {
    const response = await this.$store.dispatch("GET_AVAILABLE_LOCATIONS");
    this.states = response.data;
    this.today = moment()
      .add(5, "d")
      .format("YYYY-MM-DD");
    await this.$store.dispatch("FETCH_TERMINAL_PRICINGS");
    this.payload.businessId = GET_USER_BUSINESS_ID();
    this.outrightPurchase = this.mposTerminal.outrightPurchase;
    this.leaseToOwn = this.mposTerminal.leaseToOwn;
    this.subscription = this.mposTerminal.subscription;
    this.payload.terminalPrice.accessories = this.mposTerminal.accessories;
  },
};
</script>

<style>

</style>
